import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './components/SignIn';
import WelcomeForm from './components/WelcomeForm';
import UserList from './components/UserList';
import Feedback from './components/Feedback'; // Import the Feedback component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Route for the SignIn component */}
          <Route path="/signin" element={<SignIn />} />
          {/* Route for the WelcomeForm or Home component */}
          <Route path="/" element={<WelcomeForm />} />
          {/* Route for the UserList component */}
          <Route path="/userlist" element={<UserList />} />
          {/* Route for the Feedback component */}
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
