import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserList.css'; // Ensure the CSS file contains the updated styles

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/signin'); // Redirect if token is missing
      return;
    }

    const fetchData = async () => {
      try {
        const [usersResponse, feedbackResponse] = await Promise.all([
          fetch('/api/users', {
            headers: {
              Authorization: token, // Include token in headers
            },
          }),
          fetch('/api/feedback', {
            headers: {
              Authorization: token, // Include token in headers
            },
          }),
        ]);

        if (
          usersResponse.status === 403 ||
          usersResponse.status === 401 ||
          feedbackResponse.status === 403 ||
          feedbackResponse.status === 401
        ) {
          navigate('/signin'); // Redirect if unauthorized
        } else {
          const usersData = await usersResponse.json();
          setUsers(usersData);

          const feedbackData = await feedbackResponse.json();
          setFeedback(feedbackData);
        }
      } catch (err) {
        setError('Failed to fetch data');
      }
    };

    fetchData();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear token
    navigate('/signin'); // Redirect to signin page
  };

  return (
    <div className="user-list">
      <div className="header">
        <h2>Users</h2>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
      {error && <p className="error">{error}</p>}
      {!error && <p>Total Users: {users.length}</p>}
      <div className="card-container">
        {users.map((user) => (
          <div className="card" key={user._id}>
            <h3>
              {user.firstname} {user.lastname}
            </h3>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
            <p>
              <strong>Phone:</strong> {user.phone}
            </p>
            <p>
              <strong>Major:</strong> {user.major}
            </p>
            <p>
              <strong>Student ID:</strong> {user.id}
            </p>
          </div>
        ))}
      </div>

      {/* Feedback Section */}
      <h2>Feedback Submissions</h2>
      {feedback.length > 0 ? (
        <div className="feedback-container">
          {feedback.map((item) => (
            <div className="feedback-card" key={item._id}>
              <h3>{item.name}</h3>
              <p>
                <strong>Overall Rating:</strong> {item.overallRating}
              </p>
              <p>
                <strong>Favorite Part:</strong> {item.favoritePart}
              </p>
              <p>
                <strong>Less Helpful Part:</strong> {item.lessHelpfulPart}
              </p>
              <p>
                <strong>Would Attend More Trips:</strong> {item.attendMoreTrips}
              </p>
              <p>
                <strong>Additional Comments:</strong>{' '}
                {item.additionalComments}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p>No feedback submissions yet.</p>
      )}
    </div>
  );
};

export default UserList;
