import React, { useState } from 'react';
import './Feedback.css'; // Make sure to import the CSS file

const Feedback = () => {
  const [name, setName] = useState('');
  const [overallRating, setOverallRating] = useState('');
  const [favoritePart, setFavoritePart] = useState('');
  const [lessHelpfulPart, setLessHelpfulPart] = useState('');
  const [attendMoreTrips, setAttendMoreTrips] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be sent
    const feedbackData = {
      name,
      overallRating,
      favoritePart,
      lessHelpfulPart,
      attendMoreTrips,
      additionalComments,
    };

    // Send the data to the server
    fetch('/api/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedbackData),
    })
      .then((response) => {
        if (response.ok) {
          alert('Feedback submitted successfully!');
          // Clear the form fields
          setName('');
          setOverallRating('');
          setFavoritePart('');
          setLessHelpfulPart('');
          setAttendMoreTrips('');
          setAdditionalComments('');
        } else {
          alert('Failed to submit feedback.');
        }
      })
      .catch((error) => {
        console.error('Error submitting feedback:', error);
        alert('An error occurred while submitting feedback.');
      });
  };

  return (
    <form className="feedback-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label>
          Name<br />
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
      </div>

      <div className="form-group">
        <label>
          How would you rate today’s field trip overall?<br />
          <select
            value={overallRating}
            onChange={(e) => setOverallRating(e.target.value)}
          >
            <option value="">Select</option>
            <option value="Excellent">Excellent</option>
            <option value="Good">Good</option>
            <option value="Fair">Fair</option>
            <option value="Poor">Poor</option>
          </select>
        </label>
      </div>

      <div className="form-group">
        <label>
          What was your favorite part of the trip?<br />
          <textarea
            value={favoritePart}
            onChange={(e) => setFavoritePart(e.target.value)}
          />
        </label>
      </div>

      <div className="form-group">
        <label>
          Was there any part of the trip you found less helpful or engaging?<br />
          <textarea
            value={lessHelpfulPart}
            onChange={(e) => setLessHelpfulPart(e.target.value)}
          />
        </label>
      </div>

      <div className="form-group">
        <label>
          Would you like to attend more trips?<br />
          <select
            value={attendMoreTrips}
            onChange={(e) => setAttendMoreTrips(e.target.value)}
          >
            <option value="">Select</option>
            <option value="YES">YES</option>
            <option value="NO">NO</option>
            <option value="MAYBE">MAYBE</option>
          </select>
        </label>
      </div>

      <div className="form-group">
        <label>
          Any additional comments or suggestions?<br />
          <textarea
            value={additionalComments}
            onChange={(e) => setAdditionalComments(e.target.value)}
          />
        </label>
      </div>

      <button className="submit-button" type="submit">Submit</button>
    </form>
  );
};

export default Feedback;
