import React, { useState, useEffect } from 'react';
import './WelcomeForm.css';
import Ameer from './ameer.png';

// Modal Component
const Modal = ({ show, message, onClose }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <p>{message}</p>
        <button onClick={onClose} className="close-button">
          Close
        </button>
      </div>
    </div>
  );
};

const WelcomeForm = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    major: '',
    id: '',
  });
  const [userCount, setUserCount] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await fetch('/api/user-count');
        const data = await response.json();
        setUserCount(data.count);
        setIsLimitReached(data.count >= 27);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    fetchUserCount();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        setModalMessage('Form submitted successfully!');
        setShowModal(true);
        setUserCount(userCount + 1);
        if (userCount + 1 >= 27) setIsLimitReached(true);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit the form');
      }
    } catch (error) {
      setModalMessage(`There was an error: ${error.message}`);
      setShowModal(true);
    }
  };

  return (
    <div className="page-container">
      <Modal
        show={showModal}
        message={modalMessage}
        onClose={() => setShowModal(false)}
      />
      <div className="welcome-section">
        <div className="image-container">
          <img src={Ameer} alt="Event Banner" className="event-image" />
        </div>
        <h1>Field Trip to Cimenterie Nationale</h1>
        <p>Join us on Thursday, November 14th, from 9:00 AM to 12:00 PM for an exciting field trip to Cimenterie Nationale!</p>
        <p>Get a behind-the-scenes look at one of the largest cement producers and discover the engineering processes that shape the world’s infrastructure.</p>
        <p>Enter your details below to secure your spot for this unforgettable experience!</p>
      </div>
      <div className="form-section">
        <h2>Hello! Please tell us a little bit about yourself.</h2>
        {isLimitReached ? (
          <p className="limit-reached-message">
            The maximum number of users (27) has been reached. No more entries are allowed.
          </p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Firstname</label>
              <input
                type="text"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Lastname</label>
              <input
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="example@domain.com"
              />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="+961"
              />
            </div>
            <div className="form-group">
              <label>Major</label>
              <input
                type="text"
                name="major"
                value={formData.major}
                onChange={handleChange}
                placeholder="Your Major"
              />
            </div>
            <div className="form-group">
              <label>ID</label>
              <input
                type="text"
                name="id"
                value={formData.id}
                onChange={handleChange}
                placeholder="Your ID"
              />
            </div>
            <div className="form-buttons">
              <button type="submit" className="submit-button" disabled={isLimitReached}>
                SUBMIT
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default WelcomeForm;
